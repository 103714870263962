html, body, #root {
  height: 100%;
  overflow: hidden;
}

@media (min-width: 1279px) {
  html {
    font-size: 125%
  }
}

@media only screen and (min-width: 1090px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 620px) {
  body {
    font-size: 140%;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1550px) and (min-height: 700px) and (max-height: 750px) {
  body {
    font-size: 140%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  text-align: center;
}

@media (max-height: 554px) {
  #root {
    overflow: auto;
  }
}

textarea {
  overflow: auto;
}
