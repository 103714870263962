@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-Regular.ttf);
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-BlackExpanded.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-Light.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'SKODA Next';
  font-display: block;

  src: url(./skoda/ttf/SKODANext-LightItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SKODA Next Arabic';
  font-display: block;
  src: url(./skoda/ttf/SKODANextArabic-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'SKODA Next Arabic';
  font-display: block;
  src: url(./skoda/ttf/SKODANextArabic-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'SKODA Next Arabic';
  font-display: block;
  src: url(./skoda/ttf/SKODANextArabic-Light.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'SKODA Next Arabic';
  font-display: block;
  src: url(./skoda/ttf/SKODANextArabic-Regular.ttf);
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-Normal_4.03.ttf);
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-Normal_4.03.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-ExtendedNormal_4.03.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-Italic_4.03.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-Bold_4.03.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-BoldItalic_4.03.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-WideLight_4.03.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'AudiType';
  font-display: block;
  src: url(./audi/ttf/AudiType-Italic_4.03.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-Regular.ttf);
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-Regular.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-Light.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'SEATBCN';
  font-display: block;
  src: url(./seat/ttf/SEATBCN-LightItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-Regular.ttf);
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-Regular.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-Italic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'PorscheNextTT';
  font-display: block;
  src: url(./porsche/ttf/PorscheNextTT-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'VW';
  font-display: block;
  src: url(./vw/otf/VWHeadRegular.otf);
}

@font-face {
  font-family: 'VW';
  font-display: block;
  src: url(./vw/otf/VWHeadRegular.otf);
  font-weight: 900;
}

@font-face {
  font-family: 'VW';
  font-display: block;
  src: url(./vw/otf/VWTextRegular.otf);
  font-weight: 900;
}

@font-face {
  font-family: 'VW';
  font-display: block;
  src: url(./vw/otf/VWHeadBold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-Book.ttf);
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-Regular.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-BookItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-Book.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-Light.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'CUPRABOOK';
  font-display: block;
  src: url(./cupra/ttf/Cupra-LightItalic.ttf);
  font-weight: 100;
  font-style: italic;
}
